export const FRUITS = [
    {
      label: "mirtille",
      // radius: 40 / 2,
      radius: 17,
      color: "#F20306",
      score: 1,
    },
    {
      label: "groseille",
      // radius: 50 / 2,
      color: "#FF624C",
      radius: 20,
      score: 3,
    },  
    {
      label: "kumkwat",
      // radius: 72 / 2,
      color: "#A969FF",
      radius: 26,
      score: 6,
    },
    {
      label: "banane",
      // radius: 85 / 2,
      radius: 30,
      color: "#FFAF02",
      score: 10,
    },
    {
      label: "orange",
      // radius: 105 / 2,
      radius: 38,
      color: "#FC8611",
      score: 15,
    },
    {
      label: "apple",
      // radius: 140 / 2,
      radius: 45,
      color: "#F41615",
      score: 21,
    },
    {
      label: "pear_",
      // radius: 160 / 2,
      radius: 49,
      color: "#FDF176",
      score: 28,
    },
    {
      label: "noix_de_coco",
      // radius: 145 / 2,
      radius: 56,
      color: "#FEB6AC",
      score: 35,
    },
    {
      label: "melon",
      // radius: 220 / 2,
      radius: 67,
      color: "#F7E608",
      score: 42,
    },
    {
      label: "ananas",
      radius: 90,
      color: "#89CE13",
      score: 49,
    },
    {
      label: "watermelon",
      radius: 130,
      color: "#26AA1E",
      score: 56,
    },
  ];