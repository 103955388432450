<template>
  <div id="content">
    <header>
      <a href="https://watermelongame.fr" class="logo">WatermelonGame </a>
      <hr class="logo_hr">
    </header>
      <div class="lesdeux">
  <div>  
    <p class="score">{{ $score }}</p>
  </div>
  <div class="nextfruit">
    <p>Suivant</p>
    <img v-if="$nextfruit.value" :src="require(`@/assets/${$nextfruit.value}.png`)" :alt="$nextfruit.value">
  </div>
  </div>
  <div id="canvas"></div>
  <!-- <div class="ordre_fruit">
    <div>    
      <img v-for="(fruit, index) in fruits" :key="index" :src="getImagePath(fruit)" alt="Fruit Image">
    </div>
  </div> -->
  <section class="description_section">
    <div class="title"><h1>LE WATERMELONGAME : SUIKA GAME</h1></div>
    <hr class="titre_hr">
    <div class="description"><p>Dans le jeu WatermelonGame, aussi appelé Suika Game, le but est de faire tomber et fusionner des fruits dans une boite sans dépasser la limite.<br>Vous devez atteindre le score le plus haut possible sans que la boite ne sois pleine. Le plus gros fruit pouvant être obtenu est la pastèque !  </p>
    </div>
</section>
  <div id="modal_div">
    <div class="modal_gameOver">
          <h2>Game Over</h2>
          <p>Vous avez perdu. Voulez-vous rejouer ?</p>
          <button id="replayButton">Rejouer</button>
          <p>Votre score: <b>{{ $score }}</b> points</p>
      </div>
  </div>
</div>
</template>

<script>
import { FRUITS } from './fruit';

export default {
  name: 'App',
  data() {
    return {
      fruits: FRUITS, // Utilisez la constante FRUITS ici
    };
  },
  methods: {
    getImagePath(fruit) {
      // Assurez-vous que le chemin vers vos images est correct
      return require(`./assets/${fruit.label}.png`);
    },
  },
}
</script>


<style>
*{
  padding: 0;
  margin: 0;
}
body{
  background-color: #D4C69E;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  background-color: #D4C69E;
  padding-top: 30px;

}
#content{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
header a.logo{
  margin-left: 100px;
  font-size: 32px;
  color: #000;
  text-decoration: none;
}
header a.logo:visited {
  font-weight: 0;
}
.logo_hr{
  background-color: #91644F;
  width: 110px;
  height: 1px;
  border: 1px solid #91644F;
  margin-left: 100px;
}
.lesdeux{
  display: flex;
  justify-content: center;
  align-items: center;
}
.lesdeux div{
  padding: 0px 30px;
}
p.score {
  background-color: #91644F;
  padding: 5px 33px;
  border-radius: 5px;
  color: #fff;
  font-size: 33px;
  box-shadow: 0px 7px 10px -5px rgba(0, 0, 0, 0.75);
  animation: ScaleUp 2s ease-in-out infinite;

}
.nextfruit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: moveUpDown 2s ease-in-out infinite;
}
.nextfruit p{
  font-size: 20px;
  font-weight: 600;
  color: #5D553C;
}
.nextfruit img {
  width: 50px; 
  height: 50px;
  border: 1px solid #BEB7A1;
  border-radius: 50%;
  background-color: #fff;
  padding: 2px;
  object-fit: cover; 

}
section.description_section{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  background-color: beige;
}
div.title{
    color: #000;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 5px;
    text-align: center;
}
hr.titre_hr{
  background-color: #91644F;
  width: 200px;
  height: 3px;
  border: 1px solid #91644F;
  margin: 15px 0px;
}
.description{
    color: #2b2e3a;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    margin: 0 0 8px;
    max-width: 70%;
    text-align: center;
}
#modal_div {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    justify-content: center;
    align-items: center;
}
.modal_gameOver {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.modal_gameOver p {
    padding: 3px;
}
#replayButton {
    background-color: rgb(186, 166, 103);
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 10px 0px;
    border-radius: 5px;
    cursor: pointer;
}

@keyframes moveUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}
@keyframes ScaleUp {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@media only screen and (max-width: 768px) {
  #app {
    padding-top: 10px;
  }
  div.title {
      color: #13141c;
      font-weight: 900;
      font-size: 20px;
      line-height: 35px;
      margin: 0 0 5px;
  }
  hr.titre_hr{
  background-color: #91644F;
  width: 100px;
  height: 3px;
  border: 1px solid #91644F;
  margin: 15px 0px;
  }
  header{
    padding: 30px 0px;
    text-align: center;
  }
  header a.logo{
  margin-left: 0;
  font-size: 25px;
}
.logo_hr{
  background-color: #91644F;
  width: 110px;
  height: 1px;
  border: 1px solid #91644F;
  margin-left: calc(50% - 55px);
}
}
</style>
