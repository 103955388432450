import { createApp, ref } from 'vue'
import App from './App.vue'
import { Engine, Render, Runner, Bodies, World, Body, Sleeping, Events, Composite} from 'matter-js';
import { FRUITS } from './fruit';

const app = createApp(App)
app.config.globalProperties.$score = ref(0);
app.config.globalProperties.$nextfruit = ref("")
app.mount('#app');

let largeurEcran = window.innerWidth - 15;
let hauteurEcran = window.innerHeight - 150;

let screenWidth = window.innerWidth;
let screenHeight = window.innerHeight;

let renderWidth;

if (screenWidth <= 550) { // Si c'est un format mobile
    renderWidth = screenWidth;
} else {
    renderWidth = screenWidth - 15.001;
}

const engine = Engine.create();
const render = Render.create({
    element: document.getElementById('canvas'),
    engine: engine,
    options: {
        wireframes: false,
        background: "#D4C69E",
        width: renderWidth,
        height: hauteurEcran,
    }
});

const world = engine.world;


let currentBody = null;
let currentLine = null;
let interval = null;
let disableAction = false;
let currentFruit = null;
let leftPressed = false;
let rightPressed = false;
let mouseupProcessed = false;
let widthWall = null;
let touchStartX = 0;


const scale_animation  = (screenWidth <= 550 && screenHeight <= 1000) ?  0.5 : (screenWidth > 550 && screenHeight <= 900) ? 0.4 : 0.5

const carre = Bodies.rectangle(largeurEcran / 2, hauteurEcran / 2, 500, 525, {
    isStatic: true,
    isSensor: true,
    render: {
        fillStyle: "#ECE0AA",
    }
});
const ground = Bodies.rectangle(carre.position.x, carre.position.y + 255, 503, 15, {
    isStatic: true,
    render: {
        fillStyle: "#876D60",
    }
});

const LeftWall = Bodies.rectangle(carre.position.x - 251, carre.position.y, 2, 525, {
    isStatic: true,
    render: {
        fillStyle: "#876D60",
    }
});

widthWall = 2 

const RightWall = Bodies.rectangle(carre.position.x + 251, carre.position.y, 2, 525, {
    isStatic: true,
    render: {
        fillStyle: "#876D60",
    }
});

const topLine = Bodies.rectangle(carre.position.x, carre.position.y - 262, 500, 2, {
    isStatic: true,
    isSensor: true,
    render: { fillStyle: "#876D60" },
    label: "topLine",
});

const LeftArrete = Bodies.rectangle(carre.position.x - 238, carre.position.y - 283, 51, 2, {
    isStatic: true,
    isSensor: true,
    angle: -45,
    render: { fillStyle: "#876D60" },
    label: "leftArrete",
});

const RightArrete = Bodies.rectangle(carre.position.x + 238, carre.position.y - 283, 51, 2, {
    isStatic: true,
    isSensor: true,
    angle: 45,
    render: { fillStyle: "#876D60" },
    label: "rightArrete",
});

const TopArrete = Bodies.rectangle(carre.position.x, carre.position.y - 305, 450, 2, {
    isStatic: true,
    isSensor: true,
    render: { fillStyle: "#876D60" },
    label: "topArrete",
});






// Dimensions du rectangle
const rectWidth = 550;
const rectHeight = 50;

// Coordonnées du centre du rectangle
const rectX = carre.position.x;
const rectY = carre.position.y - 280; // Ajustez cette valeur selon vos besoins

// Dimensions des coins inclinés
const cornerWidth = 50;

// Coordonnées des coins
const leftCornerX = rectX - rectWidth / 2;
const rightCornerX = rectX + rectWidth / 2 - cornerWidth;
const topCornerY = rectY - rectHeight / 2 + 35;

// Création des triangles équilatéraux pour les coins droits
// Longueur de l'hypoténuse
const hypotenuseLength = 32;

// Longueur du côté adjacent (base du triangle)


const leftCorner = Bodies.polygon(leftCornerX + 50, topCornerY, 3, hypotenuseLength, {
    isStatic: true,
    isSensor: true,
    angle: Math.PI / 2, // Angle pour un triangle rectangle
    render: {
        fillStyle: "#ECE0AA", // Couleur beige
    }
});

const rightCorner = Bodies.polygon(rightCornerX, topCornerY, 3, hypotenuseLength, {
    isStatic: true,
    isSensor: true,
    angle: Math.PI / 2, // Angle de 90 degrés pour que le triangle soit vertical
    render: {
        fillStyle: "#ECE0AA", // Couleur beige
    }
});

// Création du rectangle horizontal entre les coins
const inclinedRect = Bodies.rectangle(rectX, rectY, rectWidth - 2 * cornerWidth, rectHeight, {
    isStatic: true,
    isSensor: true,
    render: {
        fillStyle: "#ECE0AA", // Couleur beige
    }
});

World.add(world, [leftCorner, rightCorner, inclinedRect]);


function animateScale(body, targetScale, duration) {
    const initialScale = 0.05;
    const scaleIncrement = (targetScale - initialScale) / (duration / 16.66); // 16.66 ms correspond à 1 frame à 60 FPS

    function scaleStep() {
        if (body.render.sprite.xScale < targetScale) {
            body.render.sprite.xScale += scaleIncrement;
            body.render.sprite.yScale += scaleIncrement;
            requestAnimationFrame(scaleStep);
        }
    }

    // Utiliser setTimeout pour ajouter une transition à l'échelle
    setTimeout(scaleStep, 0);
}


let fruitX = window.innerWidth / 2; // Position en x au centre de l'écran
let fruitY = null; // Position en y en haut du carré







World.add(world, [carre, ground, LeftWall, RightWall, LeftArrete, RightArrete, TopArrete]);

Render.run(render);
const runner = Runner.create();
Runner.run(runner, engine);




if (screenHeight <= 900 && screenWidth > 550) {    // format ordinateur portable

    console.log("largeur de l'écran",screenWidth);
    console.log("largeur du carre", carre.bounds.max.x);
    
    Body.scale(carre, 0.6, 0.6);
    Body.scale(leftCorner, 0.6, 0.6);
    Body.scale(rightCorner, 0.6, 0.6);
    Body.scale(inclinedRect, 0.6, 0.6);
    Body.scale(LeftWall, 0.6, 0.6);
    Body.scale(RightWall,0.6, 0.6);
    Body.scale(LeftArrete, 0.6, 0.6);
    Body.scale(RightArrete, 0.6, 0.6);
    Body.scale(topLine, 0.6, 0.6);
    Body.scale(TopArrete, 0.6, 0.6);
    Body.scale(ground,0.6, 0.5);
    widthWall = 2 * 0.6

    Body.setPosition(topLine, { x: carre.position.x, y: carre.position.y - 157});
    Body.setPosition(TopArrete, { x:carre.position.x, y: carre.position.y - 183});
    Body.setPosition(inclinedRect, { x: carre.position.x, y: carre.position.y - 167});


    Body.setPosition(leftCorner, { x: carre.position.x - 135,  y: carre.position.y - 165});
    Body.setPosition(rightCorner, { x: carre.position.x + 135, y: carre.position.y - 165});


    Body.setPosition(LeftArrete, { x: carre.position.x - 142, y: carre.position.y - 170});
    Body.setPosition(RightArrete, { x:carre.position.x + 142, y: carre.position.y - 170});


    Body.setPosition(LeftWall, { x:carre.position.x - 150, y: carre.position.y });
    Body.setPosition(RightWall, {  x:carre.position.x + 150, y: carre.position.y });
    Body.setPosition(ground, { x: carre.position.x, y: carre.position.y + 157});

}
if (screenHeight <= 1000 && screenWidth <= 550) {    // format mobile

    Body.scale(carre, 1, 0.8);
    Body.scale(leftCorner, 0.8, 0.8);
    Body.scale(rightCorner, 0.8, 0.8);
    Body.scale(inclinedRect, 0.84, 0.8);
    Body.scale(LeftWall, 1, 0.8);
    Body.scale(LeftArrete, 1, 0.8);
    Body.scale(RightWall, 1, 0.8);  
    Body.scale(RightArrete, 1, 0.8);
    Body.scale(TopArrete, 0.83, 1);

    widthWall = 2

    Body.setPosition(topLine, { x: carre.position.x, y: carre.position.y - 210});
    Body.setPosition(TopArrete, { x:carre.position.x + 7, y: carre.position.y - 245});
    Body.setPosition(inclinedRect, { x:carre.position.x + 7, y: carre.position.y - 225});


    Body.setPosition(LeftArrete, { x: 22.4 - 8, y: carre.position.y - 228});
    Body.setPosition(leftCorner, { x: 26, y: carre.position.y - 220});
    Body.setPosition(RightArrete, { x:screenWidth - 15, y: carre.position.y - 228});
    Body.setPosition(rightCorner, { x:screenWidth - 26, y: carre.position.y - 220});


    Body.setPosition(LeftWall, { x:1, y: carre.position.y });
    Body.setPosition(RightWall, { x: screenWidth - 1, y: carre.position.y });
    Body.setPosition(ground, { x: carre.position.x, y: carre.position.y + 210});

}

function getRandomFruit() {
    const randomIndex = Math.floor(Math.random() * 5)
    const fruits = FRUITS[randomIndex]

    if (currentFruit && currentFruit.label == fruits.label)
        return getRandomFruit();

    return fruits;
}



function setCollisionFilter(body) {
    const selectedFruitCollisionFilter = {
        group: -1, // Groupe utilisé pour ignorer les collisions
        category: 0x0002, // Catégorie spécifique pour le fruit sélectionné
        mask: 0x0000 // Masque nul pour ignorer toutes les collisions
    };
    
    body.collisionFilter = selectedFruitCollisionFilter;
}
function enableCollision(body) {
    body.collisionFilter = {
        group: 0, // Réinitialise le groupe
        category: 0x0001, // Réinitialise la catégorie
        mask: 0xFFFFFFFF // Réinitialise le masque pour permettre toutes les collisions
    };
}

let nextFruit = getRandomFruit();
let lastDroppedFruitPosition = null;

function addCurrentFruit(){ 
    const randomFruit = nextFruit;

    // let fruitX = window.innerWidth / 2; // Position en x au centre de l'écran
    // let fruitY = 50; // Position en y en haut du carré

    if (lastDroppedFruitPosition) {
        if (screenHeight <= 1000 && screenWidth <= 550) {  // format mobile
        fruitX = lastDroppedFruitPosition.x;
        fruitY = carre.position.y - 260;
        }
        else if (screenHeight <= 900 && screenWidth > 550 )  { // format ordinateur portable
            fruitX = lastDroppedFruitPosition.x;
            fruitY = carre.position.y - 200;
        }
        else { // format normal grand écran
            fruitX = lastDroppedFruitPosition.x;
            fruitY = carre.position.y - 330;
        }
    }  else {
        // Si c'est le début du jeu, placez le fruit au centre du carré
        if (screenHeight <= 1000 && screenWidth <= 550) {
            fruitX = carre.position.x;
            fruitY = carre.position.y - 260;
        } else if (screenHeight <= 900 && screenWidth > 550)  {
            fruitX = carre.position.x;
            fruitY = carre.position.y - 200;
        } else {
            fruitX = carre.position.x;
            fruitY = carre.position.y - 350;
        }
    }

    const scaleFactor = (screenWidth <= 550 && screenHeight <= 1000) ?  1.07 : (screenWidth > 550 && screenHeight <= 900) ? 0.7 : 1.1;
    const new_radius = (screenWidth <= 550 && screenHeight <= 1000) ? randomFruit.radius * 1.07 : (screenWidth > 550 && screenHeight <= 900) ? randomFruit.radius * 0.7 : randomFruit.radius * 1.1;
    // const carreHeight = carre.bounds.max.y - carre.bounds.min.y;
    console.log(scaleFactor, randomFruit.label);

    

    const body = Bodies.circle(fruitX, fruitY,new_radius, {
        label: randomFruit.label,
        isSleeping: true,     
        render: {
            opacity: 2,
            fillStyle: randomFruit.color,
            sprite: {
            texture: require(`@/assets/${randomFruit.label}.png`),              
            xScale: 0.4,
            yScale: 0.4,
            } 
        },
        restitution: 0,
    });

    animateScale(body, scaleFactor, 200); // 1 est l'échelle finale, 1000 est la durée de l'animation en millisecondes

    // function animateScale(body, targetScale, duration) {
    //     const initialScale = 0.05;
    //     const scaleIncrement = (targetScale - initialScale) / (duration / 16.66); // 16.66 ms correspond à 1 frame à 60 FPS
    
    //     function scaleStep() {
    //         if (body.render.sprite.xScale < targetScale) {
    //             body.render.sprite.xScale += scaleIncrement;
    //             body.render.sprite.yScale += scaleIncrement;
    //             requestAnimationFrame(scaleStep);
    //         }
    //     }
    
    //     // Utiliser setTimeout pour ajouter une transition à l'échelle
    //     setTimeout(scaleStep, 0);
    // }

    // Position du bas du fruit (supposons que le fruit est un cercle)
        const fruitBottomX = body.position.x;
        const fruitBottomY = body.position.y + new_radius;
        const groundHeight = ground.position.y - fruitBottomY - 7.5;
        
        const line = Bodies.rectangle(
            fruitBottomX, 
            fruitBottomY + groundHeight / 2, 
            4, 
            groundHeight, {
                isStatic: true,
                isSensor: true,
                render: {
                    visible: true, // Assurez-vous que la ligne est visible par défaut
                    fillStyle: 'white',
                }
            }
        );
        
    currentBody = body;
    currentLine = line;
    currentFruit = randomFruit;
    World.add(world, [line,body, topLine,  ]);


    setCollisionFilter(currentBody);
    nextFruit = getRandomFruit();
    app.config.globalProperties.$nextfruit.value = nextFruit.label;
   
}

   

window.addEventListener("keydown", (event) => {
    if (disableAction) return;

    switch (event.code) {
        case "ArrowLeft":
            if (!interval && !disableAction) {
                leftPressed = true; 
                interval = setInterval(() => {
                    const fruitRadius = currentBody.circleRadius || (currentBody.parts && currentBody.parts[0].circleRadius);
                    if (currentBody.position.x - 1.2 > LeftWall.position.x + fruitRadius + widthWall) {
                        Body.setPosition(currentBody, {
                            x: currentBody.position.x - 1.5,
                            y: currentBody.position.y,
                            
                        }, );
                        Body.setPosition(currentLine, {
                            x: currentLine.position.x - 1.5,
                            y: currentLine.position.y,
                        }, );
                    }   
                }, 1);
            }
            break;

         case "ArrowRight":
            if (!interval && !disableAction) {
                rightPressed = true; 
                interval = setInterval(() => {
                    const fruitRadius = currentBody.circleRadius || (currentBody.parts && currentBody.parts[0].circleRadius);
                    if (currentBody.position.x + 1.2 < RightWall.position.x - fruitRadius - widthWall) {
                        Body.setPosition(currentBody, {
                            x: currentBody.position.x + 1.5,
                            y: currentBody.position.y,
                        });
                        Body.setPosition(currentLine, {
                            x: currentLine.position.x + 1.5,
                            y: currentLine.position.y,
                            
                        }, );
                    }
                }, 1);
            }
            break;

        case "Space":
            disableAction = true;
            event.preventDefault();
            Sleeping.set(currentBody, false);
            if (leftPressed) {
                leftPressed = false;
            }
            if (rightPressed) {
                rightPressed = false;
            }
            enableCollision(currentBody)
            World.remove(world, currentLine);
            clearInterval(interval); 
            Body.setPosition(currentBody, {
                x: currentBody.position.x,
                y: currentBody.position.y
            });
             lastDroppedFruitPosition = {
                x: currentBody.position.x,
                y: currentBody.position.y
            };
            setTimeout(function() {
                addCurrentFruit();
                disableAction = false;
            }, 600);
            break;
    }
});

window.addEventListener("keyup", (event) => {
    switch (event.code) {
        case "ArrowLeft":
            leftPressed = false;
            break;

        case "ArrowRight":
            rightPressed = false;
            break;
    }

    if (!leftPressed && !rightPressed) {
        clearInterval(interval);
        interval = null;
    }
});

window.onkeyup = (event) => {  
    switch (event.code) {
        case "ArrowLeft":
        case "ArrowRight": 
            clearInterval(interval);
            interval = null;
    }
}


const canvas = render.canvas;

canvas.addEventListener("mouseup", () => {
    if (mouseupProcessed) {return;}
    else {
        lastDroppedFruitPosition = {
            x: currentBody.position.x,
            y: currentBody.position.y
        };
        canvas.removeEventListener('mousemove', onMouseMove);
        World.remove(world, currentLine);
        mouseupProcessed = true;
        disableAction = true;
        Sleeping.set(currentBody, false);
        if (leftPressed) {
            leftPressed = false;
        }
        if (rightPressed) {
            rightPressed = false;
        }
        enableCollision(currentBody)
        clearInterval(interval); 
        Body.setPosition(currentBody, {
            x: currentBody.position.x,
            y: currentBody.position.y
        });
        setTimeout(function() {
            addCurrentFruit();
            disableAction = false;
            mouseupProcessed = false;
        }, 600);
    }
   
})

canvas.addEventListener('mousedown', onMouseDown);

function onMouseDown(event) {
    if (mouseupProcessed) { return; }
    const mouseX = event.clientX;

    // Assurez-vous que la position en X du clic est dans les limites du mur gauche et du mur droit
    const minX = LeftWall.position.x + (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));
    const maxX = RightWall.position.x - (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));

    // Limitez la position en X du clic pour qu'elle reste dans les limites des murs gauche et droit
    const newX = Math.min(maxX, Math.max(minX, mouseX));

    // Définissez la nouvelle position en X du fruit
    Body.setPosition(currentBody, {
        x: newX,
        y: currentBody.position.y,
    });
    Body.setPosition(currentLine, {
        x: newX,
        y: currentLine.position.y,
    });

    // Réinitialisez la position initiale du clic
    touchStartX = mouseX;
    canvas.addEventListener('mousemove', onMouseMove);
}

function onMouseMove(event) {
    if (currentBody && !disableAction) {
        const canvasRect = canvas.getBoundingClientRect();
        const mouseX = event.clientX - canvasRect.left;

        // Limitez la position de currentBody.position.x pour qu'elle reste dans les limites des murs gauche et droit
        const minX = LeftWall.position.x + (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));
        const maxX = RightWall.position.x - (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));

        // Assurez-vous que la position est dans les limites
        const newX = Math.min(maxX, Math.max(minX, mouseX));

        // Mettez à jour la position de currentBody
        Body.setPosition(currentBody, {
            x: newX,
            y: currentBody.position.y
        });
        Body.setPosition(currentLine, {
            x: newX,
            y: currentLine.position.y
        });
    }
}

function onTouchStart(event) {    
    if (mouseupProcessed) { return; }
    const touch = event.touches[0];
    const touchX = touch.clientX;

    // Assurez-vous que la position en X du toucher est dans les limites du mur gauche et du mur droit
    const minX = LeftWall.position.x + (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));
    const maxX = RightWall.position.x - (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));

    // Limitez la position en X du toucher pour qu'elle reste dans les limites des murs gauche et droit
    const newX = Math.min(maxX, Math.max(minX, touchX));

    // Définissez la nouvelle position en X du fruit
    Body.setPosition(currentBody, {
        x: newX,
        y: currentBody.position.y,
    });
    Body.setPosition(currentLine, {
        x: newX,
        y: currentLine.position.y,
    });

    // Réinitialisez la position initiale du toucher
    touchStartX = touchX;
}


function onTouchMove(event) {
    if (currentBody && !disableAction) {
        event.preventDefault();
        const touch = event.touches[0];
        const touchCurrentX = touch.clientX;

        const currentX = currentBody.position.x;

        const minX = LeftWall.position.x + (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));
        const maxX = RightWall.position.x - (currentBody.circleRadius + widthWall || (currentBody.parts && currentBody.parts[0].circleRadius + widthWall));
    

        const deltaX = touchCurrentX - touchStartX;
        const newX = Math.min(maxX, Math.max(minX, currentX + deltaX));

        touchStartX = touchCurrentX; 

        if (newX < minX || newX > maxX) {
            canvas.removeEventListener('mousemove', onTouchMove);
            console.log("J'arrête d'écouter car tu viens de lâcher. Appuyez sur l'écran.");
            mouseupProcessed = true;
            disableAction = true;
            Sleeping.set(currentBody, false);

            if (leftPressed) {
                leftPressed = false;
            }

            if (rightPressed) {
                rightPressed = false;
            }

            clearInterval(interval);

            Body.setPosition(currentBody, {
                x: currentBody.position.x,
                y: currentBody.position.y,
            });
            setTimeout(function() {
                addCurrentFruit();
                disableAction = false;
                mouseupProcessed = false;
            }, 5000);
        } else {
            // Mettez à jour la position de currentBody
            Body.setPosition(currentBody, {
                x: newX,
                y: currentBody.position.y,
            });
            Body.setPosition(currentLine, {
                x: newX,
                y: currentLine.position.y,
            });
        }
    }
}

function onTouchEnd() {
    if (mouseupProcessed) { return; }
    canvas.removeEventListener('mousemove', onTouchMove);
    console.log("J'arrête d'écouter car tu viens de lâcher. Appuyez sur l'écran.");
    mouseupProcessed = true;
    disableAction = true;
    Sleeping.set(currentBody, false);

    if (leftPressed) {
        leftPressed = false;
    }

    if (rightPressed) {
        rightPressed = false;
    }
    enableCollision(currentBody)
    clearInterval(interval);

    Body.setPosition(currentBody, {
        x: currentBody.position.x,
        y: currentBody.position.y,
    });
    World.remove(world, currentLine);
    lastDroppedFruitPosition = {
        x: currentBody.position.x,
        y: currentBody.position.y
    };
    setTimeout(function() {
        addCurrentFruit();
        disableAction = false;
        mouseupProcessed = false;
    }, 600);
}

canvas.addEventListener('touchstart', onTouchStart);
canvas.addEventListener('touchend', onTouchEnd);
canvas.addEventListener('touchmove', onTouchMove);


Events.on(engine, "collisionStart", (event) => {
    event.pairs.forEach(collision => {
        if (collision.bodyA.label === collision.bodyB.label) {
            const fruit_fusion = FRUITS.find(f => f.label === collision.bodyA.label);
            app.config.globalProperties.$score.value += fruit_fusion.score;
            World.remove(world, [collision.bodyA, collision.bodyB]);

            const index = FRUITS.findIndex(fruit => fruit.label === collision.bodyA.label); 
            if (index !== FRUITS.length - 1) {
                const newFruit = FRUITS[index + 1];
                const scaleFactor = (screenWidth <= 550 && screenHeight <= 1000) ?  1.07 : (screenWidth > 550 && screenHeight <= 900) ? 0.7 : 1.1;
                const new_radius = (screenWidth <= 550 && screenHeight <= 1000) ? newFruit.radius * 1.07 : (screenWidth > 550 && screenHeight <= 900) ? newFruit.radius * 0.7 : newFruit.radius * 1.1;
                const body = Bodies.circle(
                    collision.collision.supports[0].x,
                    collision.collision.supports[0].y,
                    new_radius,
                    
                    {
                        render: {
                            fillStyle: newFruit.color,
                            sprite: { 
                            texture: require(`@/assets/${newFruit.label}.png`),
                            xScale: scale_animation,
                            yScale: scale_animation,
                          },
                        },
                        label: newFruit.label,
                        restitution: 0
                    }
                );
                World.add(world, body);

                animateScale(body, scaleFactor, 200); // 1 est l'échelle finale, 1000 est la durée de l'animation en millisecondes

            }
        }

        if (
            (FRUITS.some(fruit => fruit.label === collision.bodyA.label) ||
            FRUITS.some(fruit => fruit.label === collision.bodyB.label)) &&
            !disableAction
        ) {
            const fruitA = FRUITS.find(fruit => fruit.label === collision.bodyA.label);
            const fruitB = FRUITS.find(fruit => fruit.label === collision.bodyB.label);

            const aboveTopLineA = fruitA && collision.bodyA.position.y + fruitA.radius < topLine.position.y;
            const aboveTopLineB = fruitB && collision.bodyB.position.y + fruitB.radius < topLine.position.y;
            const position_x_fruitA = fruitA && collision.bodyA.position.x < LeftWall.position.x || fruitA && collision.bodyA.position.x  > RightWall.position.x;
            const position_x_fruitB = fruitB && collision.bodyB.position.x < LeftWall.position.x || fruitB && collision.bodyA.position.x > RightWall.position.x;


            
            if (aboveTopLineA || aboveTopLineB ||position_x_fruitA || position_x_fruitB) {
                    console.log("Game Over");
                    gameOver();
            }
                }
            });
        }
    );

const modal = document.getElementById('modal_div');

function gameOver() {
    disableAction = true;
    modal.style.display = 'flex';

    const replayButton = document.getElementById('replayButton');
    replayButton.addEventListener('click', () => {
        resetGame();
    });
}

function resetGame() {
    const allBodies = Composite.allBodies(world);
    const fruitBodies = allBodies.filter(body => {
        return body.label && FRUITS.some(fruit => fruit.label === body.label);
    });
    World.remove(world, currentLine)

    fruitBodies.forEach(fruitBody => {
        World.remove(world, fruitBody);
    });

    currentBody = null;
    currentLine = null;
    interval = null;
    disableAction = false;
    currentFruit = null;
    leftPressed = false;
    rightPressed = false;
    mouseupProcessed = false;
    touchStartX = 0;
    lastDroppedFruitPosition = null;

    app.config.globalProperties.$score.value = 0;

    nextFruit = getRandomFruit();
    addCurrentFruit();
    app.config.globalProperties.$nextfruit.value = nextFruit.label;

    modal.style.display = 'none';
}

addCurrentFruit();